import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Button, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import api from '~/services/api';

import GiftsAutoComplete from '~/components/GiftsAutocomplete';
import ActionsAutoComplete from '~/components/ActionsAutocomplete';
import { ContentPromoCad } from './styles';

export default function PromotionActionGiftForm({
  product,
  loadingData,
  draft,
  updateDraft,
  isDoubleCheck,
  setList,
  promotion,
}) {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('');
  const [gift, setGift] = useState('');

  const schema = Yup.object().shape({
    idAcao: Yup.string()
      .required('Campo obrigatório')
      .min(1, 'Campo não pode ser vazio'),
    idBrinde: Yup.string()
      .required('Campo obrigatório')
      .min(1, 'Campo não pode ser vazio'),
  });

  const handleSubmitGift = async (data, { resetForm }) => {
    try {
      setLoading(true);

      if (!draft && product) {
        // Lógica para atualizar uma promoção existente
        setLoading(true);
        await api.put(`/promotions/config/actiongifts/${product.id}`, data);
        await loadingData();
        resetForm();
        setAction(undefined);
        setGift(undefined);
        document
          .querySelector(
            '#full-width-tabpanel-2 > div > div > div:nth-child(1) > form > div > div:nth-child(2) > div.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty'
          )
          .click();
        document
          .querySelector(
            '#full-width-tabpanel-2 > div > div > div:nth-child(1) > form > div > div:nth-child(1) > div.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty'
          )
          .click();
      } else if (draft) {
        // Lógica para criar uma nova entrada em um rascunho
        setLoading(true);
        const result = await api.post(`/promotions/draft/${draft._id}/gifts`, {
          ...data,
          isDoubleCheck,
        });
        if (isDoubleCheck) {
          setList(currentList => [...currentList, ...result.data.actionGifts]);
          return true;
        }
        await updateDraft(result.data);

        resetForm();
        setAction('');
        setGift('');

        return true;
      } else if (promotion && !draft && !product) {
        data.idPromocao = promotion.idPromocao;
        setLoading(true);
        await api.post(`/promotions/action`, data);
        await loadingData();
        resetForm();
        setAction(undefined);
        setGift(undefined);
        document
          .querySelector(
            '#root > div.sc-cmjSyW.kjntNK > main > main > div > div:nth-child(1) > div.sc-bMvGRv.bFVqyf > div:nth-child(2) > div:nth-child(1) > form > div > div:nth-child(1) > div.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator'
          )
          .click();
        document
          .querySelector(
            '#root > div.sc-cmjSyW.kjntNK > main > main > div > div:nth-child(1) > div.sc-bMvGRv.bFVqyf > div:nth-child(2) > div:nth-child(1) > form > div > div:nth-child(2) > div.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator'
          )
          .click();
      }
    } finally {
      setLoading(false);
    }

    return null;
  };

  const handleActionChange = event => {
    const { value } = event.target;
    setAction(value);
  };

  const handleGiftChange = event => {
    const { value } = event.target;
    setGift(value);
  };

  useEffect(() => {
    document.getElementById('idAcao').value = action;
  }, [action]);

  useEffect(() => {
    document.getElementById('idBrinde').value = gift;
  }, [gift]);

  return (
    <>
      <div>
        <h3 style={{ marginLeft: 10, marginBottom: 20, marginTop: 40 }}>
          CADASTRO AÇÃO BRINDE
        </h3>
      </div>
      <hr style={{ margin: 10 }} />
      <Form schema={schema} onSubmit={handleSubmitGift} noValidate>
        <ContentPromoCad>
          <Grid item xs={12} md={12}>
            <ActionsAutoComplete value={action} onChange={handleActionChange} />
            <div style={{ display: 'none' }}>
              <TextField name="idAcao" type="text" />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <GiftsAutoComplete value={gift} onChange={handleGiftChange} />
            <div style={{ display: 'none' }}>
              <TextField name="idBrinde" type="text" />
            </div>
          </Grid>
          <Grid item xs={12} md={12} align="right">
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              color="primary"
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Icon>save</Icon>&nbsp;Salvar
                </>
              )}
            </Button>
          </Grid>
        </ContentPromoCad>
      </Form>
    </>
  );
}

PromotionActionGiftForm.defaultProps = {
  product: null,
  loadingData: () => {},
  draft: null,
  updateDraft: () => {},
  isDoubleCheck: false,
  setList: () => {},
  promotion: null,
};

PromotionActionGiftForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]),
  loadingData: PropTypes.func,
  draft: PropTypes.oneOfType([PropTypes.object]),
  updateDraft: PropTypes.func,
  setList: PropTypes.func,
  isDoubleCheck: PropTypes.bool,
  promotion: PropTypes.oneOfType([PropTypes.object]),
};
