import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

export default function Input({
  name,
  containerStyle,
  isPositiveNumber,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputChange = e => {
    if (isPositiveNumber) {
      const value = e.target.value.replace(/[^0-9.]/g, '');
      const isValidPositiveNumber =
        parseFloat(value) > 0 && !/^0+(\.|\d)*$/.test(value);
      if (isValidPositiveNumber) {
        e.target.value = value;
      } else {
        e.preventDefault();
      }
    }
  };

  return (
    <div style={containerStyle}>
      <TextField
        inputRef={inputRef}
        error={!!error}
        helperText={error}
        defaultValue={defaultValue}
        onInput={handleInputChange}
        {...rest}
      />
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  containerStyle: PropTypes.objectOf({}),
  isPositiveNumber: PropTypes.bool,
};

Input.defaultProps = {
  name: null,
  containerStyle: null,
  isPositiveNumber: false,
};
