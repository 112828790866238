import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const ContentPromoCad = styled(Paper)`
  padding: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
