import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { Select } from 'unform-material-ui';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, RadioGroup, Radio, Icon } from '@material-ui/core';
import { useSelector } from 'react-redux';
import createFormdata from 'object-to-formdata';
import ReactPlayer from 'react-player';

import { ProductAutocomplete } from '~/components';
import { useBannersHome } from '../context';
import Dialog from './Dialog';
import FieldImage from './ImageField';
import { useBannerForm } from './context';
import { getImagePreview } from './helpers';
import { isYouTubeURL, identifyLinkType } from '../../../../helpers/url';
import DialogRoutesApp from './AppRoutesDialog';
import DialogConfirmUrl from './ConfirmUrlDialog';
import {
  Container,
  PreviewContainer,
  FormContainer,
  TwoColumnsField,
  DateTimeField,
} from './styles';

const BannerFormDialog = () => {
  const { data, closeForm } = useBannerForm();
  const { addNewBanner, updateBanner } = useBannersHome();

  const [bannerPreview, setBannerPreview] = useState(null);
  const [isYoutubeUrl, setIsYoutubeUrl] = useState(false);
  const [isAppRoutesDialogOpen, setIsAppRoutesDialogOpen] = useState(false);
  const [confirmUrlDialogOpen, setConfirmUrlDialogOpen] = useState(false);
  const [initialUrlPathValue, setInitialUrlPathValue] = useState(data.urlPath);
  const [formState, setFormState] = useState({});
  const [showOpenUrlInWebviewSwitch, setShowOpenUrlInWebviewSwitch] = useState(
    false
  );

  const { product } = useSelector(state => state.user);

  const banner = useMemo(
    () => ({
      id: get(data, 'idBannerHome', null),
      image: get(data, 'bannerImage', null),
      url: get(data, 'url', null),
      urlPath: get(data, 'urlPath', null),
      order: get(data, 'ordem', null),
      useWebview: get(data, 'useWebview', null),
      captionButton: get(data, 'captionButton', null),
      product: get(data, 'produto.id', null),
      status: get(data, 'status', 0),
      validity: {
        start: get(data, 'dataInicio', null),
        end: get(data, 'dataFim', null),
      },
    }),
    [data]
  );

  const dialogTitle = useMemo(() => {
    const urlType = identifyLinkType(banner.urlPath ?? '');
    if (urlType === 'url') {
      const isYoutubeUrlByFnc = isYouTubeURL(banner.urlPath);

      setIsYoutubeUrl(isYoutubeUrlByFnc);
      setShowOpenUrlInWebviewSwitch(!isYoutubeUrlByFnc);
    } else {
      setShowOpenUrlInWebviewSwitch(false);
    }

    return banner.id ? `Editando banner #${banner.id}` : 'Novo banner';
  }, [banner.id]);

  const initialData = useMemo(() => {
    if (banner.id) {
      setInitialUrlPathValue(data.urlPath);

      return {
        dataInicio: get(data, 'dataInicio', null),
        dataFim: get(data, 'dataFim', null),
        status: get(data, 'status', 1),
        ordem: get(data, 'ordem', ''),
      };
    }

    return { status: 1 };
  }, [data, banner.id]);

  const hasSelectedProduct = useMemo(() => {
    return get(product, 'id', null) !== null;
  }, [product]);

  const updateField = useCallback(
    (fieldName, fieldValue) => {
      const isYoutubeUrlByFnc = isYouTubeURL(fieldValue);
      const urlType = identifyLinkType(fieldValue);

      if (fieldName === 'urlPath') {
        if (urlType === 'url' && !isYoutubeUrlByFnc) {
          setShowOpenUrlInWebviewSwitch(true);
        } else {
          setShowOpenUrlInWebviewSwitch(false);
        }

        setIsYoutubeUrl(isYoutubeUrlByFnc);
      }

      setFormState(oldState => ({ ...oldState, [fieldName]: fieldValue }));
    },
    [setFormState]
  );

  const handleSubmit = useCallback(
    async payload => {
      const isNew = banner.id === null;
      const idProduto = get(product, 'id', undefined);
      const captionButton = (
        get(formState, 'captionButton', banner.captionButton) ?? ''
      ).trim();

      const formdata = createFormdata({
        ...payload,
        idProduto,
        ...formState,
        captionButton,
      });

      if (isNew) {
        await addNewBanner(formdata).then(() => {
          closeForm();
          setTimeout(() => {
            setFormState({});
          }, 1);
        });
      } else {
        await updateBanner(banner.id, formdata).then(() => {
          closeForm();
          setTimeout(() => {
            setFormState({});
          }, 1);
        });
      }
    },
    [banner.id, formState, addNewBanner, updateBanner, closeForm, product]
  );

  const handleValidationAndSubmit = payload => {
    const urlPathForm = get(formState, 'urlPath', banner.urlPath);
    const isSameUrlPath = initialUrlPathValue === urlPathForm;

    if (!urlPathForm || isSameUrlPath) {
      handleSubmit(payload);
      return;
    }

    setConfirmUrlDialogOpen(true);
  };

  const handleCancel = useCallback(() => {
    setFormState({});
  }, [setFormState]);

  useUpdateEffect(() => {
    if (banner.product) updateField('idProduto', String(banner.product));
  }, [banner, updateField]);

  useUpdateEffect(() => {
    (async function handlePreview() {
      const preview = (await getImagePreview(banner.image)) || banner.url;
      if (banner.image) updateField('image', banner.image);
      setBannerPreview(preview);
    })();
  }, [banner.image, banner.url, updateField]);

  useEffect(() => {
    if (product.id) updateField('idProduto', product.id);
  }, [product, updateField]);

  return (
    <Dialog title={dialogTitle} onCancel={handleCancel} formId="banner-home">
      <Container>
        <PreviewContainer>
          <FieldImage
            src={bannerPreview}
            onChange={bannerImage => {
              updateField('image', bannerImage);
            }}
          />

          {isYoutubeUrl && (
            <ReactPlayer
              width={250}
              height={150}
              style={{ marginTop: 40 }}
              url={get(formState, 'urlPath', banner.urlPath)}
            />
          )}
        </PreviewContainer>

        <FormContainer
          id="banner-home"
          onSubmit={handleValidationAndSubmit}
          initialData={initialData}
        >
          <TwoColumnsField>
            <TextField
              name="ordem"
              label="Ordem"
              autoComplete="off"
              value={get(formState, 'ordem', banner.order)}
              onChange={order => {
                updateField('ordem', order.currentTarget.value);
              }}
            />
            <Select
              name="status"
              label="Status"
              options={[
                { value: 0, label: 'Inativo' },
                { value: 1, label: 'Ativo' },
              ]}
            />
          </TwoColumnsField>
          <DateTimeField
            name="dataInicio"
            format="dd/MM/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            label="Data Início"
            value={get(formState, 'dataInicio', banner.validity.start)}
            onChange={date => {
              updateField('dataInicio', date);
            }}
          />
          <DateTimeField
            name="dataFim"
            format="dd/MM/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            label="Data Fim"
            value={get(formState, 'dataFim', banner.validity.end)}
            onChange={date => {
              updateField('dataFim', date);
            }}
          />
          <TextField
            name="urlPath"
            label="URL/Deeplink"
            margin="normal"
            autoComplete="off"
            value={
              get(formState, 'urlPath', banner.urlPath) ||
              get(formState, 'urlPath', '')
            }
            onChange={newUrlPath => {
              updateField('urlPath', newUrlPath.currentTarget.value);
            }}
            style={{ background: '#ffffff', marginTop: 10 }}
          />

          <button
            type="button"
            onClick={() => setIsAppRoutesDialogOpen(true)}
            style={{ background: 'transparent', border: 'none' }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
                color: 'gray',
                textDecoration: 'underline',
                fontSize: 12,
                gap: 2,
              }}
            >
              <Icon fontSize="inherit" color="primary">
                info_outlined
              </Icon>
              Lista de deeplinks
            </span>
          </button>

          {!isYoutubeUrl &&
            (get(formState, 'urlPath', banner.urlPath) ||
              get(formState, 'urlPath', '')) && (
              <TextField
                name="captionButton"
                label="Texto botão"
                margin="normal"
                autoComplete="off"
                value={
                  get(formState, 'captionButton', banner.captionButton) ||
                  get(formState, 'captionButton', '')
                }
                onChange={newCaptionButton => {
                  updateField(
                    'captionButton',
                    newCaptionButton.currentTarget.value
                  );
                }}
                inputProps={{ maxLength: 20 }}
                style={{ background: '#ffffff', marginTop: 10 }}
              />
            )}

          {showOpenUrlInWebviewSwitch && (
            <>
              <RadioGroup row>
                <FormControlLabel
                  label="Abrir no app"
                  checked={get(formState, 'useWebview', banner.useWebview)}
                  onChange={() => updateField('useWebview', true)}
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  label="Abrir no navegador"
                  checked={!get(formState, 'useWebview', banner.useWebview)}
                  onChange={() => {
                    updateField('useWebview', false);
                  }}
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            </>
          )}

          {!hasSelectedProduct && (
            <div>
              <ProductAutocomplete
                label="Produto"
                valueKey="id"
                value={String(get(formState, 'idProduto', ''))}
                onChange={evt => {
                  updateField('idProduto', evt.target.value);
                }}
              />
            </div>
          )}

          <DialogConfirmUrl
            open={confirmUrlDialogOpen}
            toggleDialog={() => setConfirmUrlDialogOpen(false)}
            onSubmit={handleSubmit}
          />

          <DialogRoutesApp
            open={isAppRoutesDialogOpen}
            toggleDialog={() => setIsAppRoutesDialogOpen(false)}
            onUseRoute={e => {
              updateField('urlPath', e);
            }}
          />
        </FormContainer>
      </Container>
    </Dialog>
  );
};

export default BannerFormDialog;
