import React, { useState, useEffect } from 'react';
import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { hasPermission } from '~/components/AccessControl';
import {
  CardLabelContent,
  IconButton,
  ChangeStatusMenuButton,
  HistoryPopoverButton,
  TooltipStatusDescription,
  SendEmail,
  SendPartner,
  SendBalance,
} from '../../components';
import { Container, LeftContainer, RightContainer } from './styles';
import { useScreening } from '../context';

function ScreeningHeader({ opened = false, onCollapse }) {
  const screening = useScreening();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(true);

  const personUrl = `/person/${screening.pessoa.cpf}`;
  const isShowScreeningInstant = screeningInstant => {
    return (
      screeningInstant.promocao.instantanea &&
      screeningInstant.sorteio.tipo.codigo === 'premiosaldocarteira' &&
      screeningInstant.situacao !== 'aguardando' &&
      screeningInstant.promocao.modalidade === 'susep'
    );
  };

  const isShowScreeningNotInstant = screeningInstant => {
    return (
      !screeningInstant.promocao.instantanea &&
      screeningInstant.situacao !== 'aguardando' &&
      screeningInstant.promocao.modalidade === 'susep'
    );
  };

  const showSendBalance = screeningBalance => {
    return (
      (screeningBalance.promocao.instantanea &&
        screeningBalance.sorteio.tipo.codigo !== 'premiosaldocarteira') ||
      ((!screeningBalance.promocao.instantanea ||
        screeningBalance.sorteio.tipo.codigo === 'eglobo') &&
        screeningBalance.promocao.modalidade === 'susep')
    );
  };

  useEffect(() => {
    if (screening && screening.envioCapitalizadora) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [screening]);

  return (
    <Container className="screening-header">
      <LeftContainer>
        <CardLabelContent label="Nome" minWidth>
          <a
            href={personUrl}
            onClick={event => {
              event.preventDefault();
              history.push(personUrl);
            }}
          >
            {screening.pessoa.nome}
          </a>
        </CardLabelContent>
        <CardLabelContent marginLeft={-100} />
        <CardLabelContent label="Nome Social" minWidth>
          <a
            href={personUrl}
            onClick={event => {
              event.preventDefault();
              history.push(personUrl);
            }}
          >
            {screening.pessoa.nomeSocial}
          </a>
        </CardLabelContent>
        <CardLabelContent label="Rg" minWidth>
          <input
            type="text"
            placeholder="Número do RG"
            value={screening.rgPessoa || ''}
            onChange={e => screening.update(e.target.value, 'rgPessoa')}
          />
        </CardLabelContent>
        <CardLabelContent label="Situação" minWidth>
          {screening.situacao}
          <TooltipStatusDescription status={screening.situacao} />
        </CardLabelContent>
        <CardLabelContent label="Valor" marginLeft={-100}>
          {screening.parseAmount(screening.sorteio.valor / screening.winners)}
        </CardLabelContent>
        <CardLabelContent label="Tipo do Prêmio">
          {screening.sorteio.tipo.descricao}
        </CardLabelContent>
        <CardLabelContent label="Contemplado em">
          {screening.dataContemplado
            ? screening.parseDate(screening.dataContemplado)
            : '-'}
        </CardLabelContent>
      </LeftContainer>

      <RightContainer>
        {screening.sorteio.tipo.codigo === 'premiofisico' && (
          <SendEmail
            dataMail={{
              idTriagem: screening.id,
              end: screening.address,
              pessoa: screening.pessoa,
              titulo: screening.ganhador.numTitulo,
              dataContemplado: screening.dataContemplado,
              premio: screening.sorteio.descricao,
              hasChange: screening.dataHasChanges,
              envioEmail: screening.envioEmail,
            }}
          />
        )}
        {(isShowScreeningInstant(screening) ||
          isShowScreeningNotInstant(screening)) && (
          <SendPartner idTriagem={screening.id} disabled={isDisabled} />
        )}
        {showSendBalance(screening) && (
          <SendBalance
            params={{
              idTriagem: screening.id,
              amount: screening.sorteio.valor,
              winners: screening.winners,
            }}
          />
        )}
        <ChangeStatusMenuButton />
        <HistoryPopoverButton />
        {hasPermission('update_screening') && (
          <IconButton
            iconName="save"
            loading={screening.loading}
            onClick={() => screening.save()}
            disabled={!screening.dataHasChanges || screening.loading}
          >
            Salvar
          </IconButton>
        )}
        <button className="collapse-button" type="button" onClick={onCollapse}>
          {opened && <Icon>keyboard_arrow_up</Icon>}
          {!opened && <Icon>keyboard_arrow_down</Icon>}
        </button>
      </RightContainer>
    </Container>
  );
}

ScreeningHeader.defaultProps = {
  onCollapse: () => {},
};

ScreeningHeader.propTypes = {
  opened: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func,
};

export default ScreeningHeader;
