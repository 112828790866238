/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Typography,
  Popover,
  Menu,
  MenuItem,
  Button,
  Popper,
  Fade,
  Paper,
  Grid,
  IconButton,
} from '@material-ui/core';
import ReactJson from 'react-json-view';
import {
  MdReplay,
  MdSend,
  MdCancel,
  MdDescription,
  MdCheck,
  MdOutlineMenuBook,
  MdLocalOffer,
} from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { set, first } from 'lodash';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';
import AccessControl, { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import ConfirmationMenu from './confirmationMenu';
import DialogTransacao from './transacao';
import DialogReason from '../Dialog';
import { Table, ErrorTip } from './styles';
import EbooksPurchaseDialog from '../Ebooks';
import CouponsPurchaseDialog from '../Coupons';
import PurchaseItem from './purchaseItem';

export default function List({ person }) {
  const [arrow, setArrow] = React.useState(true);
  const [result, setResult] = useState({});
  const [transacaoDetail, setTransacaoDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [transaction, setTransaction] = useState();
  const [anchorElMenuConfirm, setAnchorElMenuConfirm] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [openMessage, setOpenMessage] = useState(false);
  const [
    openModalEbooksPurchaseDialog,
    setOpenModalEbooksPurchaseDialog,
  ] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [placement, setPlacement] = useState();
  const [anchorElMessage, setAnchorElMessage] = useState(null);
  const [confirmationMenu, setConfirmationMenu] = useState({});
  const [
    openModalCouponsPurchaseDialog,
    setOpenModalCouponsPurchaseDialog,
  ] = useState(false);

  const [sortColumn, setColumn] = React.useState('dataInsert:desc');
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const handleCloseModalEbooksPurchaseDialog = () => {
    setOpenModalEbooksPurchaseDialog(false);
  };

  const handleToggleMessage = (newPlacement, purchase) => event => {
    setAnchorElMessage(event.currentTarget);
    setOpenMessage(prev => purchase !== selectedMessage || !prev);
    setSelectedMessage(purchase);
    setPlacement(newPlacement);
  };

  const getPurchases = useCallback(async () => {
    if (!person) return;
    setLoading(true);

    try {
      if (hasPermission('read_purchases')) {
        const [column, sort] = sortColumn.split(':');
        const { data } =
          (await api.get(
            `/persons/${person.idCliente}/purchases?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`,
            {
              params: {
                column,
                sort,
              },
            }
          )) || {};

        if (data) {
          const purchaseData = data.docs.map(purchase => ({
            ...purchase,
            returnCode:
              purchase.transacao && purchase.transacao.returnCode
                ? purchase.transacao.returnCode
                : null,
          }));

          setResult({
            ...data,
            docs: purchaseData,
          });
          const { limit, page, total } = data;
          setPagination({
            ...{ limit, page, pages: Math.ceil(total / limit), totals: total },
          });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [
    setPagination,
    sortColumn,
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  useEffect(() => {
    getPurchases();
  }, [getPurchases]);

  const renderPopperMessage = () => {
    return (
      <Popper
        open={openMessage}
        anchorEl={anchorElMessage}
        placement={placement}
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'undefined',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: 10, maxWidth: '50vw' }}>
              {selectedMessage.transacao &&
                selectedMessage.transacao.returnCode !== null &&
                selectedMessage.transacao.returnMessage !== null && (
                  <>
                    <small style={{ fontWeight: 'bold', marginTop: 5 }}>
                      Retorno Cielo
                    </small>
                    <p>
                      {selectedMessage.transacao.returnCode
                        ? selectedMessage.transacao.returnCode
                        : '-'}
                      -
                      {selectedMessage.transacao.returnMessage
                        ? selectedMessage.transacao.returnMessage
                        : '-'}
                    </p>
                  </>
                )}
              {selectedMessage.transacao &&
                selectedMessage.transacao.returnCode !== '6' &&
                selectedMessage.transacao.retornoCielo !== null && (
                  <>
                    <small style={{ fontWeight: 'bold' }}>
                      <strong>Ação</strong>
                    </small>
                    <p>
                      <p>
                        {selectedMessage.transacao.retornoCielo &&
                        selectedMessage.transacao.retornoCielo.action
                          ? selectedMessage.transacao.retornoCielo.action
                          : '-'}
                      </p>
                    </p>
                  </>
                )}
              {selectedMessage.transacao &&
                selectedMessage.transacao.returnCode !== '6' &&
                selectedMessage.transacao.retornoCielo !== null && (
                  <>
                    <small style={{ fontWeight: 'bold', marginTop: 5 }}>
                      <strong>Motivo</strong>
                    </small>
                    <p>
                      <p>
                        {selectedMessage.transacao.retornoCielo &&
                        selectedMessage.transacao.retornoCielo.meaning
                          ? selectedMessage.transacao.retornoCielo.meaning
                          : '-'}
                      </p>
                    </p>
                  </>
                )}
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  };

  function handleClickMenu(event, compra) {
    setAnchorElMenu(event.currentTarget);
    setSelectedPurchase(compra);
  }

  function handleCloseMenuConfirm() {
    setAnchorElMenuConfirm(null);
  }

  function handleCloseMenu() {
    setAnchorElMenu(null);
  }

  async function handleSendEmail() {
    try {
      setLoading(true);
      if (hasPermission('resend_email')) {
        await api.post(
          `/persons/${person.idCliente}/purchases/${selectedPurchase.idCompra}/resend-email`
        );
      }
    } finally {
      handleCloseMenuConfirm();
      handleCloseMenu();
      setLoading(false);
    }
  }

  function handleClickMenuConfirm(event) {
    setAnchorElMenuConfirm(event.currentTarget);
  }

  async function handleReprocess() {
    try {
      setLoading(true);
      if (hasPermission('reprocess_purchase')) {
        await api.put(
          `/persons/${person.idCliente}/purchases/${selectedPurchase.idCompra}/reprocess`
        );
        await getPurchases();
      }
    } finally {
      handleCloseMenu();
      setLoading(false);
    }
  }

  function handleSendCouponEmail({ idCompra }) {
    setLoading(true);

    api
      .post(
        `/persons/${person.idCliente}/purchases/${idCompra}/resend-email/coupons`
      )
      .finally(() => setLoading(false));
  }

  /* usado para pegar detalhes da forma de pagamento */
  const getPaymentMethodDetail = useCallback(
    ({ purchaseId }) => {
      const personId = person.idCliente;

      api
        .get(`/persons/${personId}/purchases/${purchaseId}/payment-method`)
        .then(res => {
          if (res && res.status === 200 && res.data) {
            const prevResult = { ...result };
            const purchases = !!prevResult && prevResult.docs;

            const purchaseIndex = purchases.findIndex(
              p => p.idCompra === purchaseId
            );

            if (purchaseIndex !== -1) {
              set(
                purchases,
                `${purchaseIndex}.formaPagamento.nomeCompletoCartao`,
                res.data.Holder
              );

              set(
                purchases,
                `${purchaseIndex}.formaPagamento.validadeCartao`,
                res.data.ExpirationDate
              );

              prevResult.docs = purchases;
              setResult(() => prevResult);
            }
          }
        });
    },
    [result, person]
  );

  function renderStatus(item) {
    if (item.transacao && item.transacao.chargeback && !item.estornado) {
      return (
        <span
          style={{
            color: 'white',
            backgroundColor: 'red',
            padding: 6,
            borderRadius: 100,
          }}
        >
          Chargeback
        </span>
      );
    }
    if (item.transacao && item.transacao.chargeback && item.estornado) {
      return (
        <span
          style={{
            color: 'white',
            backgroundColor: 'red',
            padding: 6,
            borderRadius: 100,
          }}
        >
          CBK + Estorno
        </span>
      );
    }
    if (item.estornado)
      return (
        <Typography color="secondary" style={{ color: 'red' }}>
          Estornado
        </Typography>
      );
    if (item.vendaPix && item.cancelado && !item.estornar)
      return (
        <Typography color="secondary" style={{ color: 'red' }}>
          Estornado
        </Typography>
      );
    if (item.cancelado)
      return (
        <Typography color="secondary">
          {item.vendaSaldo ? 'Estornado' : 'Negado'}
        </Typography>
      );
    if (!item.status) {
      return <Typography color="textSecondary">Pendente</Typography>;
    }
    return <Typography color="primary">Aprovado</Typography>;
  }

  const getCodeAndTransactionDetail = async arrayPurchaseId => {
    const personId = person.idCliente;
    const prevResult = { ...result };
    const purchases = !!prevResult && prevResult.docs;

    setLoading(true);
    let transacao;
    let responseTransaction;
    await Promise.all(
      await arrayPurchaseId.map(async purchaseId => {
        const purchaseIndex = purchases.findIndex(
          p => p.idCompra === purchaseId
        );
        try {
          const res = await api.get(
            `/persons/${personId}/purchases/${purchaseId}/oldtransaction`
          );
          if (res && res.status === 200) {
            const transactionData = first(res.data.transaction);
            responseTransaction = transactionData.returnCode;
            const transactionResponse = first(res.data.transaction);
            transacao = transactionResponse;
          }
        } catch (err) {
          responseTransaction = '-';
          transacao = '-';
        } finally {
          if (purchaseIndex !== -1) {
            set(purchases, `${purchaseIndex}.returnCode`, responseTransaction);
            set(purchases, `${purchaseIndex}.transacao`, transacao);
            prevResult.docs = purchases;
            setResult({ ...prevResult });
          }
        }
      })
    ).finally(() => {
      setLoading(false);
    });
  };

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleOldTransaction() {
    try {
      setLoadingTransaction(true);
      const { data } = await api.get(
        `/persons/${person.idCliente}/purchases/${selectedPurchase.idCompra}/oldtransaction`
      );
      setTransaction(data);
    } catch (err) {
      setTransaction({});
      setAnchorEl();
    } finally {
      setLoadingTransaction(false);
    }
  }

  function handleViewTransaction(event) {
    if (selectedPurchase.transacao) {
      setTransaction(selectedPurchase.transacao);
    } else {
      handleOldTransaction();
    }
    setAnchorEl(event.currentTarget);
    handleCloseMenu();
  }

  function addDefaultSrc(ev) {
    ev.target.src = 'https://ui-avatars.com/api/';
  }

  const toggleDialog = async () => {
    setOpenDialog(!openDialog);
    if (!openDialog) {
      handleCloseMenu();
    }
  };

  function handleCancel() {
    toggleDialog();
  }

  function renderFormaPagamento(item) {
    if (item.checkout) {
      return (
        <>
          <p> Via PDV</p>
        </>
      );
    }

    if (item.vendaPixOpenFinance) {
      return <p>Pix via Open Finance</p>;
    }

    if (item.vendaPix) {
      return <p>Pix</p>;
    }

    if (item.vendaSaldo) {
      return <p>Saldo Apcap</p>;
    }

    if (item.vendaCartao) {
      const {
        nomeCompletoCartao: titular,
        tipoCartao,
        bandeiraCartao,
        numeroCartao,
      } = item.formaPagamento || {};

      return (
        <>
          <p>
            Cartão de {tipoCartao === 1 ? ' Débito' : ' Crédito'}{' '}
            {bandeiraCartao}
          </p>

          <p>
            {numeroCartao}

            <FaEye
              style={{ cursor: 'pointer' }}
              onClick={() =>
                getPaymentMethodDetail({ purchaseId: item.idCompra })
              }
            />
          </p>

          {!!titular && (
            <p>
              Titular: <i>{titular}</i>
            </p>
          )}
        </>
      );
    }

    return '';
  }

  const renderMenuConfirm = () => {
    if (!person || !selectedPurchase) return '';
    return (
      <Menu
        id="simple-menu-confirm"
        anchorEl={anchorElMenuConfirm}
        keepMounted
        open={Boolean(anchorElMenuConfirm)}
        onClose={() => handleCloseMenuConfirm}
      >
        <MenuItem onClick={() => handleSendEmail()}>
          <MdCheck size={25} />
          &nbsp;Enviar Email
        </MenuItem>
        <MenuItem onClick={() => handleCloseMenuConfirm()}>
          <MdCancel size={25} />
          &nbsp;Cancelar
        </MenuItem>
      </Menu>
    );
  };

  const renderOptionsMenu = () => {
    if (!person || !selectedPurchase) return '';
    const {
      cancelado,
      status,
      idCompra,
      vendaCartao,
      vendaSaldo,
      vendaPix,
    } = selectedPurchase;
    const isFinish = !cancelado && status === true;
    const isShowCancelButton =
      vendaCartao === true || vendaSaldo === true || vendaPix === true;
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={() => handleCloseMenu()}
      >
        {selectedPurchase?.produto?.codigo === 'ebook' && (
          <MenuItem
            onClick={() => {
              setOpenModalEbooksPurchaseDialog(true);
              handleCloseMenu();
            }}
          >
            <MdOutlineMenuBook size={25} />
            &nbsp;E-books comprados
          </MenuItem>
        )}
        {selectedPurchase.reprocessar ? (
          <MenuItem onClick={() => handleReprocess()}>
            <MdReplay size={25} />
            &nbsp;Reprocessar Compra
          </MenuItem>
        ) : null}
        {isFinish && (
          <MenuItem
            disabled={!person.email}
            onClick={event => handleClickMenuConfirm(event)}
          >
            <MdSend size={25} />
            &nbsp;Enviar Comprovante
            <ErrorTip check={person.email}>Cliente sem email</ErrorTip>
          </MenuItem>
        )}
        {isFinish && (
          <MenuItem
            disabled={!person.email}
            onClick={event =>
              setConfirmationMenu({
                anchor: event.currentTarget,
                onOk: () => handleSendCouponEmail({ idCompra }),
                onCancel: () => setConfirmationMenu({}),
              })
            }
          >
            <MdSend size={25} />
            &nbsp;Enviar Cupons
          </MenuItem>
        )}

        {/* btn cancelar compra */}
        {isShowCancelButton && (
          <MenuItem onClick={() => handleCancel()}>
            <MdCancel size={25} />
            &nbsp;Cancelar Compra
          </MenuItem>
        )}

        <MenuItem onClick={() => setOpenModalCouponsPurchaseDialog(true)}>
          <MdLocalOffer size={25} />
          &nbsp;Ver Cupons
        </MenuItem>

        {/* // onClick={event => handleViewTransaction(event)} */}

        {/* ver transação */}
        {!!selectedPurchase.formaPagamento && (
          <AccessControl can="view_transaction">
            <MenuItem onClick={event => handleViewTransaction(event)}>
              <MdDescription size={25} />
              &nbsp;Ver Transação
            </MenuItem>
          </AccessControl>
        )}
        {!!selectedPurchase.formaPagamento && (
          <MenuItem
            onClick={() => {
              setTransacaoDetail(selectedPurchase);
              handleCloseMenu();
            }}
          >
            <MdDescription size={25} />
            &nbsp;Consultar Transação
          </MenuItem>
        )}
      </Menu>
    );
  };
  function handleSort(column, sort) {
    const oldSort = sort === 'asc' ? 'desc' : 'asc';

    const newSortColumn = `${column}:${sort}`;
    const oldSortColumn = `${column}:${oldSort}`;
    setArrow(!arrow);

    if (sortColumn === oldSortColumn) setColumn(newSortColumn);
    else if (sortColumn === newSortColumn) setColumn(oldSortColumn);
    else setColumn(newSortColumn);
  }

  const arrayOfPurchases = [];

  const renderAllStatus = item => {
    if (!item.transacao && item.formaPagamento) {
      arrayOfPurchases.push(item.idCompra);
      return (
        <Button
          onClick={() => {
            getCodeAndTransactionDetail(arrayOfPurchases);
          }}
        >
          <FaEye style={{ cursor: 'pointer' }} />
        </Button>
      );
    }
    return renderStatus(item);
  };

  function renderReturnCode(item) {
    if (item.returnCode) {
      return item.returnCode;
    }

    if (!item.returnCode && !item.transacao && item.vendaCartao) {
      return (
        <Button onClick={() => getCodeAndTransactionDetail(arrayOfPurchases)}>
          <FaEye style={{ cursor: 'pointer' }} />
        </Button>
      );
    }
    return <>-</>;
  }

  if (loading && (!result || !result.docs)) {
    return <CircularProgress align="center" size={24} />;
  }

  if (result && result.docs && result.docs.length > 0) {
    return (
      <>
        {openModalEbooksPurchaseDialog && (
          <EbooksPurchaseDialog
            open={openModalEbooksPurchaseDialog}
            personId={selectedPurchase.idPessoa}
            onClose={handleCloseModalEbooksPurchaseDialog}
            purchaseId={selectedPurchase.idCompra}
          />
        )}

        {openModalCouponsPurchaseDialog && (
          <CouponsPurchaseDialog
            open={openModalCouponsPurchaseDialog}
            purchase={selectedPurchase}
            onClose={() => setOpenModalCouponsPurchaseDialog(false)}
          />
        )}

        {renderOptionsMenu()}
        <ConfirmationMenu {...confirmationMenu} />
        {renderMenuConfirm()}
        {selectedMessage && renderPopperMessage()}
        <Table>
          <thead>
            <tr>
              <th
                onClick={() => {
                  handleSort('dataCompra', 'asc');
                }}
                style={{ cursor: 'pointer' }}
              >
                Data da Compra
                {arrow ? (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundUp />
                  </IconButton>
                ) : (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundDown />
                  </IconButton>
                )}
              </th>
              <th
                onClick={() => {
                  handleSort('produto.titulo', 'asc');
                }}
                style={{ cursor: 'pointer' }}
              >
                Produto
                {arrow ? (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundUp />
                  </IconButton>
                ) : (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundDown />
                  </IconButton>
                )}
              </th>
              <th
                onClick={() => {
                  handleSort('promocao.dataReferencia', 'asc');
                }}
                style={{ cursor: 'pointer' }}
              >
                Promoção
                {arrow ? (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundUp />
                  </IconButton>
                ) : (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundDown />
                  </IconButton>
                )}
              </th>
              <th
                onClick={() => {
                  handleSort('formaPagamento.bandeiraCartao', 'asc');
                }}
                style={{ cursor: 'pointer' }}
              >
                Forma de Pagamento
                {arrow ? (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundUp />
                  </IconButton>
                ) : (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundDown />
                  </IconButton>
                )}
              </th>
              <th
                onClick={() => {
                  handleSort('valor', 'asc');
                }}
                style={{ cursor: 'pointer' }}
              >
                Valor
                {arrow ? (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundUp />
                  </IconButton>
                ) : (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundDown />
                  </IconButton>
                )}
              </th>
              <th
                onClick={() => {
                  handleSort('status', 'asc');
                }}
                style={{ cursor: 'pointer' }}
              >
                Situação
                {arrow ? (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundUp />
                  </IconButton>
                ) : (
                  <IconButton size="medium" disableRipple>
                    <IoIosArrowRoundDown />
                  </IconButton>
                )}
              </th>
              <th>Retorno Cielo</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {result.docs.map((item, index) => {
              return (
                <PurchaseItem
                  key={item.idCompra}
                  item={item}
                  index={index}
                  person={person}
                  handleToggleMessage={handleToggleMessage}
                  handleClickMenu={handleClickMenu}
                  setOpenMessage={setOpenMessage}
                  addDefaultSrc={addDefaultSrc}
                  renderFormaPagamento={renderFormaPagamento}
                  renderAllStatus={renderAllStatus}
                  renderReturnCode={renderReturnCode}
                />
              );
            })}
          </tbody>
        </Table>

        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}
        {/* TRANSAÇÃO */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {loadingTransaction ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              style={{ padding: 10 }}
            >
              <span style={{ marginRight: 10 }}>Buscando Transação</span>
              <CircularProgress size={24} />
            </Grid>
          ) : (
            <ReactJson
              sortKeys
              theme="hopscotch"
              displayDataTypes={false}
              src={transaction}
            />
          )}
        </Popover>

        {selectedPurchase && person && (
          <DialogReason
            open={openDialog}
            toggleDialog={toggleDialog}
            person={person}
            purchase={selectedPurchase}
          />
        )}

        {!!transacaoDetail && (
          <DialogTransacao
            open={!!transacaoDetail}
            purchase={transacaoDetail}
            person={person}
            onClose={() => setTransacaoDetail(null)}
          />
        )}
      </>
    );
  }
  return <NoRecord />;
}

List.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};

List.defaultProps = {
  person: null,
};
