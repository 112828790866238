import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { parseISO, format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { setDraft } from '~/store/modules/user/actions';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import { decimalFormat } from '~/helpers/number';
import useProducts from '~/components/ProductList/useProducts';

function getDateFormat(data) {
  let newDate;
  if (isValid(parseISO(data))) {
    const onlyDate = format(parseISO(data), 'yyyy-MM-dd');
    newDate = parseISO(`${onlyDate}T23:00:00Z`);
  } else {
    newDate = parseISO(`${data}T23:00:00.000Z`);
  }
  return format(newDate, 'yyyy-MM-dd HH:mm', { locale: ptBR });
}

export default function useDraft() {
  useProducts();
  const dispatch = useDispatch();
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [deletingDraft, setDeleginDraft] = useState(false);

  const [id, setId] = useState();

  const updateDraft = useCallback(_draft => {
    if (
      _draft.data &&
      _draft.data.promotion &&
      _draft.data.promotion.valorProduto
    ) {
      const normalizedValue = String(
        _draft.data.promotion.valorProduto
      ).replace(',', '.');

      _draft.data.promotion.valorProduto = decimalFormat(normalizedValue);
    }
    dispatch(setDraft(_draft));
  }, []);

  const getDraft = useCallback(
    async _id => {
      try {
        setLoadingDraft(true);
        if (hasPermission('read_promotion_draft')) {
          const { data } = (await api.get(`/promotions/drafts/${_id}`)) || {};
          if (data) {
            if (data.data.promotion.dataReferencia) {
              data.data.promotion.dataReferencia = getDateFormat(
                data.data.promotion.dataReferencia
              );
            }
            updateDraft(data);
          } else {
            updateDraft({});
          }
        }
      } finally {
        setLoadingDraft(false);
      }
    },
    [updateDraft]
  );

  const deleteDraft = useCallback(
    async draftId => {
      try {
        setDeleginDraft(true);
        const { data: newDraft } = await api.delete(
          `/promotions/drafts/${draftId}`
        );
        updateDraft(newDraft);
      } catch (err) {
        // nothing...
      } finally {
        // eslint-disable-line
        setDeleginDraft(false);
      }
    },
    [updateDraft]
  );

  useEffect(() => {
    if (id) getDraft(id);
  }, [id, getDraft]);
  return { loadingDraft, deletingDraft, updateDraft, setId, deleteDraft };
}
