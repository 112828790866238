import { useState, useEffect, useCallback } from 'react';
import { flatten, get, isArray, isEmpty, set, uniq } from 'lodash';
import toFormdata from 'object-to-formdata';
import update from 'immutability-helper';

import api from '~/services/api';

export default function usePromotion({
  id,
  pretty = true,
  fetchConfig = false,
}) {
  const [promotion, setPromotion] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productConfig, setProductConfig] = useState({});
  const [saving, setSaving] = useState(false);
  const [sweepstakes, setSweepstakes] = useState([]);
  const [draftSweepstakes, setDraftSweepstakes] = useState([]);
  const [ebooks, setEbooks] = useState([]);
  const [updatingRegulation, setUpdatingRegulation] = useState(false);
  const [sweepstakeTypeFiltered, setSweepstakeTypeFiltered] = useState([]);

  useEffect(() => {
    setSweepstakes([...get(promotion, 'sorteios', [])]);
    setDraftSweepstakes(promotion?.promotionSweepstakeDraft || []);
  }, [onSave, promotion]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);

        const { data } = await api.get(`/promotions/${id}/ebooks`);
        if (!isEmpty(data)) {
          setEbooks(data);
        }
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [promotion]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const resPromo = await api.get(`/promotions/${id}`, {
          params: { pretty },
        });
        setPromotion(get(resPromo, 'data', {}));

        const productId = get(resPromo, 'data.produto.id', null);
        if (fetchConfig && !!productId) {
          const resConfig = await api.get(
            `/promotions/config/${productId}?byProduct=true`
          );
          const config = get(resConfig, 'data.config', {});
          setProductConfig(config);
        }
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id, pretty, fetchConfig]);

  function onUpdateWinner({ nome, cidade, bairro, estado, avatar, ...person }) {
    const copy = { ...promotion };
    const sorteios = copy.sorteios || [];

    const ganhadores = flatten(
      sorteios.map((sorteio, idx) =>
        (sorteio.ganhadores || []).map(winner => ({
          ...winner,
          idSorteio: sorteio.idSorteio,
          sorteioIdx: idx,
        }))
      )
    );

    const oldWinner = ganhadores.find(({ id: wId }) => wId === person.id);
    const winnerIdx = sorteios[oldWinner.sorteioIdx].ganhadores.findIndex(
      ({ id: wId }) => wId === person.id
    );

    const winner = {
      ...oldWinner,
      nome,
      cidade,
      bairro,
      estado,
      avatar: (avatar && `${avatar}?v=${Date.now()}`) || undefined,
    };

    // -- update winner inside promotion
    copy.sorteios[winner.sorteioIdx].ganhadores.splice(winnerIdx, 1, winner);

    // -- update promotion
    setPromotion(copy);
  }

  function handleUpdateStatus(status) {
    setLoading(true);

    api
      .put(`/promotions/${id}`, { status })
      .then(res => {
        if (res && res.data) {
          setPromotion({
            ...promotion,
            ...res.data,
          });
        }
      })
      .finally(() => setLoading(false));
  }

  const replacePromotion = useCallback(newPromotion => {
    setPromotion(newPromotion);
  }, []);

  const savePromotion = useCallback(
    async newData => {
      setSaving(true);
      try {
        const { data: res } = await api.put(`/promotions/${id}`, newData, {
          params: { pretty },
        });
        const sorteios = [...get(promotion, 'sorteios', [])];
        const promotionSweepstakeDraft = promotion?.promotionSweepstakeDraft;

        setPromotion({ ...res, sorteios, promotionSweepstakeDraft });
      } finally {
        setSaving(false);
      }
    },
    [promotion, id, pretty]
  );

  const changeRegulation = useCallback(
    async (regulamento, keepData) => {
      setUpdatingRegulation(true);
      const formData = toFormdata({ regulamento });
      try {
        const res = await api.post(`/promotions/${id}/regulation`, formData);
        const url = get(res, 'data.url', '');
        const newPromotion = { ...promotion };
        set(newPromotion, 'regulamento.url', url);
        setPromotion({ ...newPromotion, ...keepData });
      } finally {
        setUpdatingRegulation(false);
      }
    },
    [promotion, id]
  );

  const movePromotionSweepstake = useCallback(
    ({ sweepstake, newIndex, oldIndex }) => {
      const newPromotion = update(promotion, {
        sorteios: {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, sweepstake],
          ],
        },
      });
      setPromotion(newPromotion);
    },
    [promotion]
  );

  const appendSweepstakeTypeFilter = useCallback(
    types => {
      const newTypes = isArray(types) ? types : [types];
      setSweepstakeTypeFiltered(oldTypes => uniq([...oldTypes, ...newTypes]));
    },
    [setSweepstakeTypeFiltered]
  );

  const toggleSweepstakeTypeFilter = useCallback(
    type => {
      setSweepstakeTypeFiltered(oldState => {
        if (!oldState.includes(type)) return [...oldState, type];
        return oldState.filter(t => t !== type);
      });
    },
    [setSweepstakeTypeFiltered]
  );

  return {
    setPromotion,
    sweepstakes,
    draftSweepstakes,
    ebooks,
    setSweepstakes,
    setDraftSweepstakes,
    promotion,
    loading,
    onUpdateWinner,
    handleUpdateStatus,
    productConfig,
    replacePromotion,
    savePromotion,
    saving,
    updatingRegulation,
    changeRegulation,
    movePromotionSweepstake,
    appendSweepstakeTypeFilter,
    sweepstakeTypeFiltered,
    toggleSweepstakeTypeFilter,
    setSweepstakeTypeFiltered,
    setOnSave,
    onSave,
  };
}
