import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import md5 from 'md5';
import { getRandomColor } from '~/helpers/color';
import { setProducts as setActionProducts } from '~/store/modules/user/actions';

import api from '~/services/api';

const productSpec = applySpec({
  id: prop('idProduto'),
  titulo: prop('descricao'),
  cod: prop('codigo'),
  imagem: prop('imagem'),
  instantaneo: prop('instantaneo'),
});

const getProducts = pipe(prop('docs'), map(productSpec));

export default function useProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { products: userProductList } = useSelector(({ user }) => user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userProductList || userProductList.length === 0) {
      setLoading(true);
      try {
        api
          .get('/products?limit=70')
          .then(({ data }) => {
            if (data)
              setProducts(
                getProducts(data).map(p => ({
                  ...p,
                  hash: md5(p.id),
                  color: getRandomColor(),
                }))
              );
            dispatch(
              setActionProducts(
                getProducts(data).map(p => ({
                  ...p,
                  hash: md5(String(p.id)),
                  color: getRandomColor(),
                }))
              )
            );
          })
          .finally(() => setLoading(false));
      } catch (error) {
        setLoading(false);
      }
    } else {
      setProducts(userProductList);
    }
  }, [dispatch, userProductList]);
  return { products, loading };
}
